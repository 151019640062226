




























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { TipoDeRecurso } from '@/models/Recurso'
import ComponentResourceGuard from '@/components/shareds/ComponentResourceGuard.vue'

@Component({
	components: {
		ComponentResourceGuard,
	},
})
export default class TelaGenericaDeFaq extends Vue  {
	@Prop({ default: 'Adicionar', type: String }) mensagemAdicionar!: string
	@Prop({ type: String }) rotaPrincipal!: string
	@Prop({ type: String }) recurso?: TipoDeRecurso
	@Prop() childProps!: any
	@Prop() podeCadastrar?: boolean 

	get recursoNormalizado() {
		return this.recurso || this.$route.meta?.recurso
	}

	get abaLateral() {
		return this.$vuetify.breakpoint.xs || this.$route.name !== this.rotaPrincipal
	}

}

